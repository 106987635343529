<template>
  <div class="my_news">
    <div class="content_box">
      <div class="nav color99">
        <li :class="navId == '' ? 'active' : ''" @click="changeNav('')">
          <span class="point"></span><span>全部资讯</span>
        </li>
        <li
          :class="navId == item.id ? 'active' : ''"
          @click="changeNav(item.id)"
          v-for="item in navList"
          :key="item.id"
        >
          <span class="point"></span
          ><span
            style="
              display: inline-block;
              width: 57px;
              height: 58px;
              overflow: hidden;
            "
            >{{ item.name }}</span
          >
        </li>
      </div>
      <div class="list" v-if="list.length != 0">
        <div
          class="item"
          v-for="item in list"
          :key="item.id"
          @click="toDetails(item.id)"
        >
          <img
            :src="
              item.picUrl ? item.picUrl : require('../../assets/no_pic.png')
            "
            alt=""
          />
          <div class="content">
            <div class="left font16" style="color: #707070">
              {{ item.createTime.split(" ")[0].split("-")[0] }}
              {{ item.createTime.split(" ")[0].split("-")[1] }}-{{
                item.createTime.split(" ")[0].split("-")[2]
              }}
            </div>
            <div class="right color33">
              <p class="title">{{ item.title }}</p>
              <p class="dis">
                {{ item.subtitle }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="empty" v-else>
        <div>
          <img src="../../assets/empty_box.png" alt="" />
          <p>暂无数据</p>
        </div>
      </div>
    </div>

    <div class="pagination">
      <el-pagination
        background
        @current-change="handleCurrentChange"
        :current-page.sync="page"
        prev-text="上一页"
        next-text="下一页"
        hide-on-single-page
        layout="prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>
<script>
import {articleCategoryList, articleList} from "@/util/api";
export default {
  data() {
    return {
      navList: [],
      navId: "",
      page: 1,
      limit: 10,
      pageSize: 0,
      list: [],
      total: 0,
    };
  },
  mounted() {
    this.getCategory();
    this.getList();
  },
  methods: {
    changeNav(i) {
      this.navId = i;
      this.page = 1;
      this.getList();
    },
    getCategory() {
      articleCategoryList({domainUrl: window.location.hostname}).then((res) => {
        this.navList = res.data;
      });
    },
    getList() {
      articleList({
        categoryId: this.navId,
        current: this.page,
        size: this.limit,
        domainUrl: window.location.hostname,
      }).then((res) => {
        this.total = res.data.total;
        // this.pageSize = res.pages;
        this.list = res.data.records;
      });
    },
    toDetails(id) {
      this.$router.push({
        path: "/newsCenter/newsDetails",
        query: {
          articleId: id,
        },
      });
    },
    handleCurrentChange(e) {
      this.page = e;
      this.getList();
    },
  },
};
</script>

<style lang="scss" scoped>
.my_news {
  width: 100%;
  min-height: 100vh;
  background-color: #f5f5f5;
  padding: 30px 0 100px;
  .content_box {
    width: 1200px;
    margin: 0 auto;
    display: flex;
    .nav {
      width: 190px;
      background-color: #fff;
      text-align: center;
      padding: 20px 0;
      border-radius: 10px;
      li {
        list-style: none;
        line-height: 60px;
        font-size: 14px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        .point {
          width: 8px;
          height: 8px;
          background: #acacac;
          margin-right: 20px;
          border-radius: 50%;
          box-shadow: 0 0 2px 3px #c9c9c9;
        }
      }
      li.active {
        color: #346edb;
        .point {
          width: 10px;
          height: 10px;
          background-color: #3972dd;
          box-shadow: 0 0 2px 3px #8eb1f3;
        }
      }
    }
    .list {
      width: 990px;
      padding: 20px;
      width: 950px;
      background-color: #fff;
      border-radius: 10px;
      margin-left: 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      .item {
        width: 465px;
        margin-bottom: 40px;
        cursor: pointer;
        .content {
          margin-top: 15px;
          display: flex;
          width: 100%;
          align-items: center;
          .left {
            width: 60px;
            height: 60px;
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
            justify-content: center;
            border: 1px solid #707070;
            font-size: 16px;
          }
          .right {
            display: flex;
            flex-direction: column;
            margin-left: 8px;
            width: calc(100% - 70px);
            .title {
              font-size: 22px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
            .dis {
              font-size: 16px;
              margin-top: 15px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }
        }
      }
      img {
        width: 465px;
        height: 180px;
        background: #f8f8f8;
        border-radius: 10px;
      }
    }
  }
}
.pagination {
  text-align: center;
  padding: 30px 0;
}
.empty {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 60px;
  width: 950px;
  img {
    width: 500px;
    height: 500px;
  }
  p {
    text-align: center;
    font-size: 16px;
    color: #666666;
  }
}
</style>
<style>
.el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #346edb;
}
</style>
